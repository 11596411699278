import { render, staticRenderFns } from "./animateIcon.vue?vue&type=template&id=c562a288&scoped=true"
import script from "./animateIcon.vue?vue&type=script&lang=js"
export * from "./animateIcon.vue?vue&type=script&lang=js"
import style0 from "./animateIcon.vue?vue&type=style&index=0&id=c562a288&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c562a288",
  null
  
)

export default component.exports