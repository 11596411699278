<template>
  <div style="width: 100%; height: 100%" ref="svgabox"></div>
</template>
<script>
import SVGA from "svgaplayerweb";
export default {
  props: {
    type: String,
  },
  methods: {
    init() {
      let player = new SVGA.Player(this.$refs.svgabox);
      let parser = new SVGA.Parser(this.$refs.svgabox);
      parser.load("/svga/" + this.type + ".svga", function (videoItem) {
        player.setVideoItem(videoItem);
        player.startAnimation();
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>